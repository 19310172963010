import { LocalNotifications } from "@capacitor/local-notifications"
import { useGlobalToast, useCurrentUserProfile } from "~/composables/states"
import { formatDate, toggleAskNotificationPermissions } from "~/utilities/helpers"

// assembles the proper title for the schedule entry
export const getEntryTitle = (entry) => {
    return entry.attributes.parentTitle && entry.attributes.scheduleEventTitle
        ? `${entry.attributes.parentTitle}: ${entry.attributes.scheduleEventTitle}`
        : entry.attributes.scheduleEventTitle ?? entry.attributes.parentTitle
}

// local notifications list state
export const usePendingLocalNotifications = () => useState('usePendingLocalNotifications', () => null)

// update the global state
export const setPendingLocalNotifications = async () => {
    const pendingLocalNotifications = usePendingLocalNotifications()
    pendingLocalNotifications.value = await LocalNotifications.getPending()
}

// check if the entry is in the local notifications list
const checkNotificationsList = (entry) => {
    const pendingLocalNotifications = usePendingLocalNotifications()
    return pendingLocalNotifications.value?.notifications.some(
        (notification) => notification.extra.id === entry.id
    ) || false;
}

// schedule a local notification
export const scheduleLocalNotification = async (entry) => {
    const idNumber = entry.id.split(":")
    const id = Number(idNumber[1])
    const currentUserProfile = useCurrentUserProfile()
    const globalToast = useGlobalToast()

    const entryStartDate = await new Date(entry.attributes.start)
    //const entryStartDateInFiveSeconds = new Date(Date.now() + 5000)
    const title = `${getEntryTitle(entry)} is starting now on ${entry.station}!`

    const body = entry.attributes.scheduleEventTitle ? `${entry.attributes.scheduleEventTitle}` : ''
    const serializedEntry = JSON.stringify(entry);
    const parsedEntry = JSON.parse(serializedEntry);

    const notificationBody = {
        notifications: [
            {
                title,
                body,
                id,
                schedule: { at: /* entryStartDateInFiveSeconds */entryStartDate, allowWhileIdle: true },
                //schedule: { at: new Date(Date.now() + 5000) },
                sound: "notification.wav",
                actionTypeId: "route-live",
                extra: parsedEntry,
            },
        ],
    }
    if (currentUserProfile.value.receive_general_notifications) {
        if (!checkNotificationsList(entry)) {
            await LocalNotifications.schedule(notificationBody)
            setPendingLocalNotifications()
            globalToast.value = {
                severity: "success",
                summary: `Notification set for ${formatDate(
                    entry.attributes.start,
                    "h:mm a EEE, MMM do "
                )}`,
                life: 3000,
                closable: true,
            }

        } else {
            await LocalNotifications.cancel(notificationBody)
            setPendingLocalNotifications()
        }
    } else {
        // ask permissions and try again
        await toggleAskNotificationPermissions()
    }
}

// initialize local notifications and add listener to handle when tapping on a notification and what the app should do
export const initLocalNotifications = async () => {
    setPendingLocalNotifications()
    // Method called when tapping on a push notification
    await LocalNotifications.addListener(
        "localNotificationActionPerformed",
        (data) => {

            // we use the slug in the notification data to create the query variable
            window.location.href = `/live?slug=${data.notification.extra.slug}`
        }
    )
}

// cancel all pending notifications if they exist and alert the user
export const cancelAllPendingNotifications = async () => {
    try {
        const pending = await LocalNotifications.getPending();
        if (pending.notifications.length > 0) {
            const globalToast = useGlobalToast()
            await LocalNotifications.cancel({ notifications: pending.notifications });
            globalToast.value = {
                severity: "warn",
                summary: "Notifications are off. All scheduled show notifications have been cancelled",
                life: 6000,
                closable: true,
            }
        }
    } catch (error) {
        console.error('Error cancelling notifications:', error);
    }
};