import revive_payload_client_4sVQNw7RlN from "/code/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/code/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/code/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_I4Hv4qpJHd from "/code/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_yVLowv6hDl from "/code/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/code/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/code/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/code/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_UzIc2qvBnC from "/code/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import components_plugin_KR1HBZs4kY from "/code/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/code/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/code/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/code/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import gsapPlugin_HlwIIyl85L from "/code/.nuxt/gsapPlugin.mjs";
import plugin_t2GMTTFnMT from "/code/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import ionic_JZxaXwxCQa from "/code/node_modules/@nuxtjs/ionic/dist/runtime/ionic.mjs";
import router_guards_4BGKgZxC1H from "/code/plugins/router-guards.js";
import error_handler_VFH3VvK7yG from "/code/plugins/error-handler.js";
import firebase_Tc1lVUbp7M from "/code/plugins/firebase.js";
import analytics_client_mtqVw3YRXk from "/code/plugins/analytics.client.ts";
import sentry_client_GoGQuZo4Et from "/code/plugins/sentry.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_I4Hv4qpJHd,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  cross_origin_prefetch_client_UzIc2qvBnC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  gsapPlugin_HlwIIyl85L,
  plugin_t2GMTTFnMT,
  ionic_JZxaXwxCQa,
  router_guards_4BGKgZxC1H,
  error_handler_VFH3VvK7yG,
  firebase_Tc1lVUbp7M,
  analytics_client_mtqVw3YRXk,
  sentry_client_GoGQuZo4Et
]